import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import google from "../assets/img/google.png";
import linkedin from "../assets/img/linkedin.png";
import epic_x from "../assets/img/EPIC-X.png";
import review from "../assets/img/Review.jpg";
import chat from "../assets/img/login_chat.jpg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Switch } from "antd";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [inputvalue, setInputvalue] = useState({
    email: "",
    password: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const setValue = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;

    setInputvalue(() => {
      return {
        ...inputvalue,
        [name]: value,
      };
    });
  };

  const images = [review, chat, review, chat];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentIndex) =>
        currentIndex === images.length - 1 ? 0 : currentIndex + 1
      );
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const LoginSubmit = async (e) => {
    e.preventDefault();

    console.log(inputvalue);
    const error = {};
    if (!inputvalue.email) {
      console.log("Email is Required!");
    }
    if (!inputvalue.password) {
      error.password = "password is required";
    }
    console.log(process.env.REACT_APP_BACKEND_URL);
    if (Object.keys(error).length === 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/mainlogin`,
          { inputvalue },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W",
            },
          }
        );
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Login Successfully!",
          });
          setInputvalue({
            email: "",
            password: "",
          });
          const tenant_name = response.data.tenant_name;
          console.log(tenant_name);
          setTimeout(() => {
            navigate(`/${tenant_name}/products`, { state: tenant_name });
            // window.location.href='http://localhost:3000/CustomBox';
          }, 1000);
        } else if (response.status === 201) {
          Swal.fire({
            icon: "error",
            title: "Username or password not found",
          });
        } else if (response.status === 201) {
          Swal.fire({
            icon: "error",
            title: "Wrong Password !",
          });
        } else if (response.status === 500) {
          Swal.fire({
            icon: "error",
            title: "Server error,Try again !",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "info",
          title: "Bad Gate Way!",
        });
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "Enter All Fields !",
      });
    }
  };

  return (
    <div>
      <div className="d-flex">
        <div className="first-half col-6 border d-none d-md-block">
          <div className="banner">
            <img
              src={images[currentImageIndex]}
              alt={`Slide ${currentImageIndex}`}
            />
          </div>
          <div className="dots-container d-flex align-items-center justify-content-center w-50">
            {images.map((image, index) => (
              <span
                key={index}
                className={`dot ${index === currentImageIndex ? "active" : ""}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
        </div>
        <div className="second-half w-100 vh-100">
          <div className="m-3">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div style={{ marginLeft: "10px" }}>
                {/* <img
                  src={epic_x}
                  alt=""
                  style={{ width: "130px", height: "25px" }}
                /> */}
              </div>
              <div className="d-flex" style={{ fontSize: "14px", gap: "1px" }}>
                <p>Don’t have an account?</p>
                <Link
                  to="/Register"
                  style={{ textDecoration: "none", color: "blue" }}
                >
                  <p>Sign up!</p>
                </Link>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mt-1">
              <h3 className="ff">Welcome Back</h3>
              <p
                style={{
                  fontSize: "15px",
                  lineHeight: "1em",
                  color: "#7E7E7E",
                }}
              >
                Login into your account
              </p>
            </div>
            <div className="sign-in d-flex gap-3 align-items-center justify-content-center mt-3">
              <button>
                <img src={google} alt="" style={{ width: "24px" }} />
                Google
              </button>
              <button>
                <img src={linkedin} alt="" style={{ width: "28px" }} />
                Linkedin
              </button>
            </div>
            <div className="continue d-flex flex-column align-items-center justify-content-center mt-4">
              <hr className="w-60" />
              <p style={{ color: "black" }}>Or continue with</p>
            </div>
            <div className="form-area mt-1">
              <form className="d-flex flex-column gap-4 align-items-center justify-content-center">
                <input
                  type="email"
                  onChange={setValue}
                  value={inputvalue.email}
                  name="email"
                  placeholder="Email"
                  className="w-60"
                />
                <div className="input-wrapper w-60">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="w-100"
                    onChange={setValue}
                    value={inputvalue.password}
                    name="password"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </form>
              <div className="d-flex align-items-center justify-content-center mt-3"style={{flexWrap:'wrap'}}>
                <span className="d-flex" style={{width:'200px'}}>
                  <div
                    onClick={handleToggle}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: isChecked ? "flex-end" : "flex-start",
                      width: "50px",
                      height: "25px",
                      borderRadius: "20px",
                      backgroundColor: isChecked ? "#0666F2" : "#ccc",
                      cursor: "pointer",
                      padding: "3px",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        transition: "0.4s",
                      }}
                    />
                  </div>
                  {/* <Switch style={{height:'25px',borderRadius:'20px'}}/> */}
                  <p
                    style={{ fontSize: "14px", lineHeight: "10.5px" }}
                    className="p-2"
                  >
                    Remember Me
                  </p>
                </span>
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "14.5px",
                    color: "red",
                    cursor: "pointer",
                    width:"150px"
                  }}
                >
                  Recover Password
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <button
                  type="submit"
                  className="w-60 "
                  style={{ fontSize: "16px" }}
                  onClick={LoginSubmit}
                >
                  LOG IN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
