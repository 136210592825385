import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./RegisterPage.css";
import google from "../assets/img/google.png";
import linkedin from "../assets/img/linkedin.png";
import epic_x from "../assets/img/EPIC-X.png";
import review from "../assets/img/Review.jpg";
import chat from "../assets/img/login_chat.jpg";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const RegisterPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [date, setDate] = useState();
  const [inputvalue, setInputvalue] = useState({
    name: "",
    email: "",
    url: "",
    password: "",
    confirmpassword: "",
  });
  const [registerdata, setRegisterData] = useState({
    product: "",
    plan_name: "",
    plan_type: "",
  });
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const product = searchParams.get("product");
    const plan = searchParams.get("plan");
    const type = searchParams.get("type");
    // const extract =plan.split('_');
    // console.log(product,extract[0],extract[1]);
    setRegisterData({
      product: product,
      plan_name: plan,
      plan_type: type,
    });
  }, []);
  const setValue = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;
    setInputvalue(() => {
      return {
        ...inputvalue,
        [name]: value,
      };
    });
  };

  const images = [review, chat, review, chat];

  useEffect(() => {
    const currentDate = new Date();
    // Extract day, month, and year components
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
    const year = currentDate.getFullYear();

    // Format the date in DDMMYYYY format
    const formattedDate = `${day}${month}${year}`;
    console.log(formattedDate); // Example: 20042024
    setDate(formattedDate);
  }, []);
  // Get the current date

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentIndex) =>
        currentIndex === images.length - 1 ? 0 : currentIndex + 1
      );
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!confirmPassword);
  };

  const RegisterSubmit = async (e) => {
    e.preventDefault();

    console.log(inputvalue);
    const error = {};
    if (!inputvalue.name) {
      error.name = "Name is Required!";
    }
    if (!inputvalue.email) {
      error.email = "Email is Required!";
    }
    if (!inputvalue.url) {
      error.url = "Store url is required";
    }
    if (!inputvalue.password) {
      error.password = "password is required";
    }
    if (!inputvalue.confirmpassword) {
      error.confirmPassword = "confirmPassword is required";
    }
    console.log(process.env.REACT_APP_BACKEND_URL);
    if (Object.keys(error).length === 0) {
      if (inputvalue.password.length > 7) {
        if (inputvalue.password === inputvalue.confirmpassword) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/register`,
              { inputvalue, date, registerdata },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "$2a$12$tXgoc0t8Pfva7LmlyQ6/aOtB//vaEWOcdV3ElI82pAlevLYSLeg6W",
                },
              }
            );
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Register Successfully!",
              });
              setInputvalue({
                name: "",
                email: "",
                url: "",
                password: "",
                confirmpassword: "",
              });
              setTimeout(() => {
                window.location.href = "/login";
              }, 1000);
            } else if (response.status === 202) {
              console.log(response);
              Swal.fire({
                icon: "warning",
                title: "Email or URL already exists!",
              });
            } else if (response.status === 422) {
              Swal.fire({
                icon: "warning",
                title: "Try Again Later!",
              });
            } else if (response.status === 500) {
              Swal.fire({
                icon: "error",
                title: "Register not success,Try again !",
              });
            }
          } catch (error) {
            Swal.fire({
              icon: "info",
              title: "Bad Gate Way!",
            });
          }
        } else {
          Swal.fire({
            icon: "info",
            title: "Password Mismatch !",
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "Password must be greater or equal to 8!",
        });
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "Enter All Fields !",
      });
    }
  };
  return (
    <div>
      <div className="d-flex">
        <div className="first-half col-6 border d-none d-md-block">
          <div className="banner">
            <img
              src={images[currentImageIndex]}
              alt={`Slide ${currentImageIndex}`}
            />
          </div>
          <div className="dots-container d-flex align-items-center justify-content-center w-50">
            {images.map((image, index) => (
              <span
                key={index}
                className={`dot ${index === currentImageIndex ? "active" : ""}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
        </div>
        <div className="second-half vh-100 w-100">
          <div className="m-3">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="w-30" style={{ marginLeft: "10px" }}>
                {/* <img 
                  src={epic_x}
                  alt=""
                  style={{ width: "130px", height: "25px" }}
                />*/}
              </div>
              <div className="d-flex" style={{ fontSize: "15px", gap: "5px" }}>
                <p>Have an account?</p>
                <Link
                  to="/login"
                  style={{ color: "blue", textDecoration: "none" }}
                >
                  Sign in!
                </Link>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h3 className="ff">Get Started With EPIC-X</h3>
              <p
                style={{
                  fontSize: "15px",
                  lineHeight: "1em",
                  color: "#7E7E7E",
                }}
              >
                Getting started is easy
              </p>
            </div>
            <div className="sign-in d-flex gap-3 align-items-center justify-content-center">
              <button>
                <img src={google} alt="" style={{ width: "24px" }} /> Google
              </button>
              <button>
                <img src={linkedin} alt="" style={{ width: "28px" }} /> Linkedin
              </button>
            </div>
            <div className="continue d-flex flex-column align-items-center justify-content-center">
              <hr className="w-60" />
              <p style={{ color: "black" }}>Or continue with</p>
            </div>
            <div className="form-area">
              <form className="d-flex flex-column gap-3 align-items-center justify-content-center">
                <input
                  type="text"
                  onChange={setValue}
                  value={inputvalue.name}
                  name="name"
                  placeholder="Full Name"
                  className="w-60"
                />
                <input
                  type="email"
                  onChange={setValue}
                  value={inputvalue.email}
                  name="email"
                  placeholder="Enter Email"
                  className="w-60"
                />
                <input
                  type="text"
                  onChange={setValue}
                  value={inputvalue.url}
                  name="url"
                  placeholder="Store URL"
                  className="w-60"
                />
                <div className="input-wrapper w-60">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    onChange={setValue}
                    value={inputvalue.password}
                    name="password"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                <div className="input-wrapper w-60">
                  <input
                    type={confirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={setValue}
                    value={inputvalue.confirmpassword}
                    name="confirmpassword"
                  />
                  <FontAwesomeIcon
                    icon={confirmPassword ? faEyeSlash : faEye}
                    className="toggle-password"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                </div>
                <button type="submit" className="w-60" onClick={RegisterSubmit}>
                  Create Account
                </button>
              </form>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mt-2">
              <span style={{ fontSize: "14px", lineHeight: "25px" }}>
                By continuing you indicate that you read and agreed to the
              </span>
              <span style={{ fontSize: "14px", lineHeight: "25px" }}>
                Terms of Use
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
