import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate,useLocation } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import profile from '../assets/img/Logout.png'
import poweroff from '../assets/img/poweroff.svg'
import email from '../assets/img/Email Campaign Ico.png'
import b2b from '../assets/img/B2B.png'
import b2c from '../assets/img/B2C.png'
import popup from '../assets/img/Popup.png'
import chat from '../assets/img/Chat.png'
import review from '../assets/img/Review.png'


const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract epicxTenantId from location state
  const tenant_name = location.state;

  console.log('Epicx Tenant ID:', tenant_name); // Log the value

  // Function to handle B2B icon click
  const handleB2BClick = () => {
    console.log('Epicx Tenant ID before navigation:', tenant_name);
    window.location.href = `http://localhost:3022/${tenant_name}/b2b/admin`;
  };

  // const handleChatClick = () =>{
  //   window.location.href = `https://chat-001.netlify.app/dashboard`
  // }

  const handleCard1ButtonClick = () => {
    // Add the navigation logic here
    navigate('/template'); // Replace '/template' with the actual path to your template page
  };

  return (
    <div className="h-100">
    {/* Header Section */}
    <div className="w-100 p-3 d-flex align-items-center justify-content-between bg-primary" style={{ height: '65px', background: 'linear-gradient(90.02deg, #6A11CB 1.55%, #2575FC 99.98%)' }}>
      <div className="d-flex align-items-center">
        {/* Increased Width for Back to Login Button */}
        <button className="btn btn-primary me-3 custom-back-btn" onClick={() => navigate('/login')} style={{ width: '250px' }}>
  <FontAwesomeIcon icon={faLongArrowAltLeft} className="me-2" />
  <span className="d-none d-md-inline">Back to Login</span>
</button>


        <div className="container rounded-pill">
          <div className="input-group">
            <div className="input-group-prepend bg-white">
              <span className="input-group-text"style={{height:'40px'}}>
                <BsSearch />
              </span>
            </div>
            <input className="form-control border-left-0 " placeholder="Search ..." />
          </div>
        </div>
      </div>

        {/* Custom User Box */}
<div className="form-group mb-4 w-25 mt-3 d-flex justify-content-end align-items-center gap-3">

{/* Round Lock Icon */}
<div className="rounded-circle overflow-hidden ms-2"  style={{ width: '40px', height: '35px', backgroundColor: 'white' }}>
  <img src={profile} alt="" className="img-fluid ml-2" style={{ width: '25px', height: '25px', margin: '5px' }} />
</div>

{/* Username */}
<span className="fw-bold ml-3">{tenant_name}</span>

{/* Lock Icon */}
<img src={poweroff} alt="Lock Icon" className="img-fluid ml-3" style={{ width: '25px', height: '30px' }} />
</div>
</div>


      {/* Container 2: Another container placed below */}
      <div className="container mt-3">
        {/* Row for Cards */}
        <div className="row mt-3">
          {/* Card 1 */}
          <div className="col-md-4 mb-3">
            <div className="card mt-4 wx-4" style={{ width: '25rem', height: '280px' ,backgroundColor: '#F8F3F6'}}>
              <div className="card-body d-flex flex-column align-items-center">
              {/* Round Gray Icon */}
<div className="rounded-circle overflow-hidden ms-2 d-flex" style={{justifyContent:'center', width: '62px', height: '62px', backgroundColor: '#FFFFFF' }}>
  <img src={email} alt="" className="img-fluid ml-2 mt-2 " style={{ width: '40px', height: '42px', margin: '5px' }} />
</div>

                {/* Text below the circle */}
                <div className="text-center flex-grow-1 mt-2">
                  <p className="fw-bold">Email Campaigns</p>
                  <p className="mb-3">An email campaign is a series of marketing efforts that are sent via email to multiple people at once.</p>
                </div>
                <div className="text-center">
                <div className="btn btn-primary" onClick={handleCard1ButtonClick} style={{ background: '#232D3B', width: '150px', borderRadius: '50px' }}>
    Click Here
</div>

                </div>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-4 mb-3">
            <div className="card mt-4 mx-3" style={{ width: '25rem', height: '280px',backgroundColor: '#F8F3F6' }}>
              <div className="card-body d-flex flex-column align-items-center">
              <div className="rounded-circle overflow-hidden ms-2 d-flex" style={{justifyContent:'center', width: '62px', height: '62px', backgroundColor: '#FFFFFF' }}>
  <img src={b2b} alt="" className="img-fluid ml-2 mt-2" style={{ width: '40px', height: '42px', margin: '5px' }} />
</div>
                <div className="text-center flex-grow-1">
                  <p className=" fw-bold">Business to Business</p>
                  <p className="mb-3">A transaction or business conducted between one business and another, such as a wholesaler and retailer.</p>
                </div>
                <div className="text-center">
                <div className="btn btn-primary" style={{ background: '#232D3B', width: '150px', borderRadius: '50px' }}onClick={handleB2BClick}>
  Click Here
</div>

                </div>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-4 mb-3">
            <div className="card mt-4 mx-4" style={{ width: '25rem', height: '280px' ,backgroundColor: '#F8F3F6'}}>
              <div className="card-body d-flex flex-column align-items-center">
              <div className="rounded-circle overflow-hidden ms-2 d-flex" style={{justifyContent:'center', width: '62px', height: '62px', backgroundColor: '#FFFFFF' }}>
  <img src={b2c} alt="" className="img-fluid ml-2 mt-3 " style={{ width: '40px', height: '38px', margin: '5px' }} />
</div>
                <div className="text-center flex-grow-1">
                  <p className=" fw-bold">Business to Consumer</p>
                  <p className="mb-3">(B2C) refers to the process of selling products and services directly between a business and consumers who are the end-users of its products or services.</p>
                </div>
                <div className="text-center">
                <div className="btn btn-primary" style={{ background: '#232D3B', width: '150px', borderRadius: '50px' }}>
  Click Here
</div>

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Row for Cards */}
        <div className="row mt-3">
          {/* Card 4 */}
          <div className="col-md-4 mb-3">
            <div className="card mt-4 mx-2" style={{ width: '25rem', height: '280px',backgroundColor: '#F8F3F6' }}>
              <div className="card-body d-flex flex-column align-items-center">
              <div className="rounded-circle overflow-hidden ms-2 d-flex" style={{justifyContent:'center', width: '62px', height: '62px', backgroundColor: '#FFFFFF' }}>
  <img src={popup} alt="" className="img-fluid ml-2 mt-2 " style={{ width: '40px', height: '38px', margin: '5px' }} />
</div>
                <div className="text-center flex-grow-1">
                  <p className=" fw-bold">Popup Builder</p>
                  <p className="mb-2">A popup builder is a plugin that enables a company to add popups to its website and informative popups and place them on sites to improve conversions and increase sales.</p>
                </div>
                <div className="text-center">
                <div className="btn btn-primary" style={{ background: '#232D3B', width: '150px', borderRadius: '50px' }}>
  Click Here
</div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3">
      <div className="card mt-4 mx-3" style={{ width: '25rem', height: '280px', backgroundColor: '#F8F3F6' }}>
        <div className="card-body d-flex flex-column align-items-center">
          <div className="rounded-circle overflow-hidden ms-2 d-flex" style={{justifyContent:'center', width: '62px', height: '62px', backgroundColor: '#FFFFFF' }}>
            <img src={chat} alt="" className="img-fluid ml-2 mt-2" style={{ width: '41px', height: '45px', margin: '5px' }} />
          </div>
          <div className="text-center flex-grow-1">
            <p className="fw-bold">Chat Automations</p>
            <p className="mb-2">Make service and support a breeze. With our AI-powered virtual agents, you can automate conversations, cut costs, and delight customers all at the same time.</p>
          </div>
          <div className="text-center">
            <div className="btn btn-primary" style={{ background: '#232D3B', width: '150px', borderRadius: '50px' }}>
              Click Here
            </div>
          </div>
        </div>
      </div>
    </div>

          {/* Card 6 */}
          <div className="col-md-4 mb-3">
            <div className="card mt-4 mx-4" style={{ width: '25rem', height: '280px',backgroundColor: '#F8F3F6' }}>
              <div className="card-body d-flex flex-column align-items-center">
              <div className="rounded-circle overflow-hidden ms-2 d-flex" style={{justifyContent:'center', width: '62px', height: '62px', backgroundColor: '#FFFFFF' }}>
  <img src={review} alt="" className="img-fluid ml-2 mt-3 " style={{ width: '41px', height: '37px', margin: '5px' }} />
</div>
                <div className="text-center flex-grow-1">
                  <p className=" fw-bold">Review Management</p>
                  <p className="mb-3">You can use a review management system to request reviews from consumers, monitor new reviews, and respond to reviews.</p>
                </div>
                <div className="text-center">
                <div className="btn btn-primary" style={{ background: '#232D3B', width: '150px', borderRadius: '50px' }}>
  Click Here
</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
