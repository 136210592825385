import React from 'react';
import { useState } from 'react';
import './Pricing.css';
import tic from '../assets/img/blue_check.png'

const PopupPrice = () => {
  const [isYearly, setIsYearly] = useState(false);

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  const monthprice ={
    free:0,
    growth:7.99,
    professional:34.99,
    premium:69.99,
  }
  const yearprice ={
    free:0,
    growth:79,
    professional:349,
    premium:699,
  }
  const reward={
    free:1000,
    growth:20000,
    professional:100000,
    premium:200000,
  }
  function handleGetStarted(plans) {
    const product=`popup`
    let pricingType='';
    if(plans==='free'){
      pricingType='both'
    }else{
    pricingType = isYearly ? 'year' : 'month';
    }
    // const plan=`${plans}_${pricingType}`
    const  impressions=reward[plans];
    // const price = isYearly ? yearprice[plan] : monthprice[plan];
    window.location.href = `/Register?product=${product}&plan=${plans}&type=${pricingType}`;
};
  return (
    <div className="container d-flex mt-5 mb-4" style={{height:'auto'}}>
    <div className="mt-5 w-100">
      <div className="d-flex align-item-center justify-content-center mt-5">
          <div className="d-flex flex-column text-center">
            <h2>Streamline your teamwork. Start free.</h2>
            <p>Choose the perfect plan for your business needs</p>
          </div>
      </div>
      <div className="d-flex flex-column mt-5">
      <div className="d-flex align-item-center justify-content-center">
        <div className="back">
              <label className={`toggle ${isYearly ? 'yearly' : 'monthly'}`}>
                <input type="checkbox" checked={isYearly} onChange={handleToggle} />
                <span className="toggle-text">Monthly</span>
              </label>

              <label className={`toggle ${!isYearly ? 'yearly' : 'monthly'}`}>
                <input type="checkbox" checked={!isYearly} onChange={handleToggle} />
                <span className="toggle-text">Yearly</span>
              </label>
        </div>
      </div>
      <div className='d-flex gap-4 align-item-center justify-content-center mt-3' style={{width:'100%',flexWrap:'wrap'}}>
          <div className="pricing-card-1"style={{height:'640px'}}>
            <div className="p-4">
              <h4 className="bold">Free</h4>
              <p>Best for personal use</p>
              <div className="price-display mt-5">
                   {isYearly ? (
                    <div className="d-flex" style={{background:'transparent'}}>
                    <h2>${yearprice.free}</h2>
                    <p className='mt-2'>/year</p>
                    </div>
                  ) : (
                    <div className="d-flex" style={{background:'transparent'}}>
                    <h2>${monthprice.free}</h2>
                    <p className='mt-2'>/month</p>
                    </div>
                  )}
                </div>
           </div>
              <div className="p-4">
                <h6 className='bold'>What you get:</h6>
                <div className="pricing-list d-flex flex-column">
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p> 1,000 impressions/month (est 5K pageviews)</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unlimited popups</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unlimited autoresponders</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>All features</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Branded</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Chat & email support</p>
                    </li>
                  </div>
              </div>
              <div className="d-flex align-item-center justify-content-center">
              <button className="btn-start" onClick={() => handleGetStarted('free')}>Get Started</button>
              </div>
          </div>
          <div className="d-flex flex-column pricing-card-2"style={{height:'640px'}}>
            <div className="p-4">
              <h4 className="bold">Growth</h4>
              <p>Best for personal use</p>
              <div className="price-display mt-5">
              {isYearly ? (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${yearprice.growth}</h2>
                        <p className='mt-2'>/year</p>
                        </div>
                      ) : (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${monthprice.growth}</h2>
                        <p className='mt-2'>/month</p>
                        </div>
                      )}
              </div>
           </div>
           <div className="p-4">
                <h6 className='bold'>All free features, plus:</h6>
                <div className="pricing-list d-flex flex-column">
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>20,000 impressions/month (est 100K pageviews)</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unlimited popups</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unlimited autoresponders</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>All features</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unbranded</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Chat & email support</p>
                    </li>
                  </div>
              </div>
              <div className="d-flex align-item-center justify-content-center">
              <button className="btn-start" onClick={() => handleGetStarted('growth')}>Get Started</button>
                </div>
          </div>
          <div className="d-flex flex-column pricing-card-3"style={{height:'640px'}}>
            <div className="p-4">
              <h4 className="bold">Professional</h4>
              <p>Best for personal use</p>
              <div className="mt-5 price-display">
              {isYearly ? (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${yearprice.professional}</h2>
                        <p className='mt-2'>/year</p>
                        </div>
                      ) : (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${monthprice.professional}</h2>
                        <p className='mt-2'>/month</p>
                        </div>
                      )}
              </div>
           </div>
           <div className="p-4">
                <h6 className='bold'>All free features, plus:</h6>
                   <div className="pricing-list d-flex flex-column">
                      <li>
                        <img src={tic} alt="" className="tic" />
                        <p>100,000 impressions/month (est 500K pageviews)</p>
                      </li>
                      <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unlimited popups</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unlimited autoresponders</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>All features</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unbranded</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Chat & email support</p>
                    </li>
                    </div>
              </div>
              <div className="d-flex align-item-center justify-content-center">
              <button className="btn-start" onClick={() => handleGetStarted('professional')}>Get Started</button>
                </div>
          </div>
          <div className="d-flex flex-column pricing-card-4"style={{height:'640px'}}>
            <div className="p-4">
              <h4 className="bold">Premium</h4>
              <p>Best for personal use</p>
              <div className="price-display mt-5">
              {isYearly ? (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${yearprice.premium}</h2>
                        <p className='mt-2'>/year</p>
                        </div>
                      ) : (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${monthprice.premium}</h2>
                        <p className='mt-2'>/month</p>
                        </div>
                      )}
              </div>
           </div>
           <div className="p-4">
                <h6 className='bold'>All free features, plus:</h6>
                <div className="pricing-list d-flex flex-column">
                  <li>
                    <img src={tic} alt="" className="tic" />
                    <p>200,000 impressions/month (est 1M pageviews)</p>
                  </li>
                  <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unlimited popups</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unlimited autoresponders</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>All features</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Unbranded</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Chat & email support</p>
                    </li>
                  </div>
              </div>
              <div className="d-flex align-item-center justify-content-center">
              <button className="btn-start" onClick={() => handleGetStarted('premium')}>Get Started</button>
                </div>
          </div>
        </div>
      </div>
    </div>
   </div>
  );
};

export default PopupPrice;
