// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    font-family: inter;
}
p{
    line-height: 27px;
    font-size: 17px;
    letter-spacing: 0.2px;
    color:#505050;
}
li{
    line-height: 27px;
    font-size: 17px;
    letter-spacing: 0.2px;
    color:#505050;
}
span{
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: 20px;
    display: inline-block;
}

a:hover{
    color:rgb(4, 4, 141);
}`, "",{"version":3,"sources":["webpack://./src/Components/privacy.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,qBAAqB;IACrB,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,qBAAqB;IACrB,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;IACjB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":["*{\n    font-family: inter;\n}\np{\n    line-height: 27px;\n    font-size: 17px;\n    letter-spacing: 0.2px;\n    color:#505050;\n}\nli{\n    line-height: 27px;\n    font-size: 17px;\n    letter-spacing: 0.2px;\n    color:#505050;\n}\nspan{\n    padding-top: 15px;\n    padding-bottom: 15px;\n    font-weight: bold;\n    font-size: 20px;\n    display: inline-block;\n}\n\na:hover{\n    color:rgb(4, 4, 141);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
