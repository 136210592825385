import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React, { useEffect } from 'react';
import './Components/assets/vendor/aos/aos.css';
import './Components/assets/vendor/bootstrap/css/bootstrap.min.css';
import './Components/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './Components/assets/vendor/glightbox/css/glightbox.min.css';
import './Components/assets/vendor/remixicon/remixicon.css';
import './Components/assets/vendor/swiper/swiper-bundle.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import MainContent from './Components/MainContent';
import RegisterPage from './Components/RegisterPage/RegisterPage';
import ChatPrice from './Components/Pricing/ChatPrice';
import PopupPrice from './Components/Pricing/PopupPrice';
import EmailPrice from './Components/Pricing/ReviewPrice';
import All from './Components/Pricing/All';
import Login from './Components/RegisterPage/Login';
import Priceheader from './Components/Priceheader';
import Subscribe from './Components/PopUpEmail/Subscribe';
import Unsubscribe from './Components/PopUpEmail/Unsubscribe';
import Products from './Components/Products/Products';
import PrivacyPolicy from './Components/PrivacyPolicy';
import PopupGuide from './Components/PopUp-Video/PopupGuide';

AOS.init();

function App() {

  useEffect(() => {
    const aosInit = () => {
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false
      });
    };
    aosInit();
    return () => {
      AOS.refresh(); // Refresh AOS to remove any animations
    };
  }, []); 
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
          <>
          <Header />
          <MainContent />
          <Footer />
        </>
            }/>
          <Route path='/ChatPrice' element={<>
                <Priceheader/>
                <ChatPrice />
                <Footer />
              </>
            }/>
            <Route path='/PopupPrice' element={<>
                <Priceheader/>
                <PopupPrice />
                <Footer />
              </>
            }/>
            <Route path='/EmailPrice' element={<>
                <Priceheader />
                <EmailPrice />
                <Footer />
              </>
            }/>
            <Route path='/All' element={<>
                <Priceheader />
                <All />
                <Footer />
              </>
            }/>
            <Route path='/PrivacyPolicy' element={<>
                <Priceheader />
                <PrivacyPolicy />
                <Footer />
              </>
            }/>
        <Route path='/Register' element={<RegisterPage/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/:clientname/products' element={<Products/>}/>
        <Route path='/api/subscribe' element={<Subscribe/>}/>
        <Route path='/api/unsubscribe' element={<Unsubscribe/>}/>
        <Route path='/popup-guide' element={<PopupGuide/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
